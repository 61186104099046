import VERSION from '../constants/apiVersionMap'

export const recombeeConfigs = {};

// "userId={user_id},scenario=home_shows_fr,region= ca-east,databaseId=club-illico-dev,recommendation_filter=ItemsToUser"

recombeeConfigs.dev = {
  databaseId: "", // Not Available
  publicKey: "", // Not Available
};
recombeeConfigs.qa = {
  databaseId: "", // Not Available
  publicKey: "", // Not Available
};
recombeeConfigs.staging = {
  databaseId: "club-illico-dev",
  publicKey: "ZNV94MqtUAy8fNNa8BZqiM51iqBQ90FAXRLR5muDNLJYQKxzwsv5tPDhJdVez8GX",
  region: "ca-east",
};

recombeeConfigs.preprod = {
  databaseId: "club-illico-preprod",
  publicKey: "ykIjgOq1MGOOvoBhds8eXdnBV3SLyP6zzapHYiOgyZica1svdkzwuI1L63EYUezZ", 
  region: "ca-east",
};

recombeeConfigs.prod = {
  databaseId: "club-illico-prod",
  publicKey: "2xpA38ZnsowNCb3bAKGSh0Oa1HjoG8DU0lNz2U0TEWz2wmDrMGG0IclcsbxIKBku",
  region: "ca-east",
};


/** -- VDT--- */
export const contentfulConfig = {}
contentfulConfig.dev = {
  url: `https://api-videotron-devint.create.diagnal.com${VERSION.fetchContentful}/config`
}
contentfulConfig.qa = {
  url: `https://api-videotron-qa.create.diagnal.com${VERSION.fetchContentful}/config`
}
contentfulConfig.staging = {
  url: `https://api-videotron-uat.create.diagnal.com${VERSION.fetchContentful}/config`
}
contentfulConfig.preprod = {
  url: `https://preprod-api.illicoplus.ca${VERSION.fetchContentful}/config`
}
contentfulConfig.prod = {
  url: `https://api.illicoplus.ca${VERSION.fetchContentful}/config`
}
/* --------------------------------------------- */
export const firebaseConfig = {}
firebaseConfig.dev = {
  apiKey: 'AIzaSyAfZEgdZPfVHbKYpzx0Oi4cEdRQw-dnkPI',
  authDomain: 'club-illico-internal.firebaseapp.com',
  databaseURL: 'https://club-illico-internal.firebaseio.com',
  projectId: 'club-illico-internal',
  storageBucket: 'club-illico-internal.appspot.com',
  messagingSenderId: '474952067599',
  appId: '1:474952067599:web:0ddf9c0c5a4f1d34a5517a',
  measurementId: 'G-HYVNMBL170'
}
firebaseConfig.qa = {
  ...firebaseConfig.dev,
  appId: '1:474952067599:web:13b5e756554240b2a5517a',
  measurementId: 'G-66DRT0S4TP'
}
firebaseConfig.staging = {
  apiKey: 'AIzaSyCB6Ek0z3rERkCj_gco90stekxgmzDLlz4',
  authDomain: 'videotron-262515.firebaseapp.com',
  databaseURL: 'https://videotron-262515.firebaseio.com',
  projectId: 'videotron-262515',
  storageBucket: 'videotron-262515.appspot.com',
  messagingSenderId: '1040370687994',
  appId: '1:1040370687994:web:6546d065d0a2994b130e43',
  measurementId: 'G-ZY34L930D8'
}
firebaseConfig.preprod = {
  apiKey: 'AIzaSyCB6Ek0z3rERkCj_gco90stekxgmzDLlz4',
  authDomain: 'videotron-262515.firebaseapp.com',
  databaseURL: 'https://videotron-262515.firebaseio.com',
  projectId: 'videotron-262515',
  storageBucket: 'videotron-262515.appspot.com',
  messagingSenderId: '1040370687994',
  appId: '1:1040370687994:web:f867825628a43655130e43',
  measurementId: 'G-6L1G5K9478'
}
firebaseConfig.prod = firebaseConfig.preprod
/* --------------------------------------------- */
export const bugsnagConfig = {}
bugsnagConfig.dev = {
  id: '0452168de24afcabf756115ed295b11b'
}
bugsnagConfig.qa = bugsnagConfig.dev
bugsnagConfig.staging = {
  id: 'f1472779576f0e0ee2ad9922940d988e'
}
bugsnagConfig.preprod = {
  id: '7bfdecc36a575235098c0d7ed9bfa366'
}
bugsnagConfig.prod = bugsnagConfig.preprod
/* --------------------------------------------- */
export const logglyConfig = {}
logglyConfig.dev = {
  id: 'a36cd2f8-de84-44d8-8625-3dd573de8020'
}
logglyConfig.qa = logglyConfig.dev
logglyConfig.staging = {
  id: '2d01bd83-fe93-470d-9af7-970ab8706219'
}
logglyConfig.preprod = {
  id: '22c5d222-1613-4f64-9809-2f403e24ada3'
}
logglyConfig.prod = logglyConfig.preprod
/* --------------------------------------------- */
export const chromecastConfig = {}
chromecastConfig.dev = {
  id: 'D8904681'
}
chromecastConfig.qa = {
  id: 'EA37AF19'
}
chromecastConfig.staging = {
  id: '77108067'
}
chromecastConfig.preprod = {
  id: 'F5629756'
}
chromecastConfig.prod = {
  id: 'F389ACCF'
}
/* --------------------------------------------- */
