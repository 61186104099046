import React, { useEffect, useState, useContext, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import useScript from 'react-script-hook'
import styled from 'styled-components'
import PlayIcon from '../icons/PlayIcon';
import PauseIcon from '../icons/PauseIcon'
import PauseIconFilled from '../icons/PauseIconFilled'
import VolumeMuteOutlineIcon from '../icons/VolumeMuteOutlineIcon'
import UpArrowIcon from '../icons/UpArrowIcon'
import { getAppLanguageWithoutRegion } from '../../utils/localStorageService'
import GoBackArrowIcon from '../icons/GoBackArrowIcon';
import SubtitleIcon from '../icons/SubtitleIcon';
import RewindIcon from '../icons/RewindIcon';
import ForwardIcon from '../icons/ForwardIcon';
import CloseIcon from '../icons/CloseIcon';
import NextBtnOutlineIcon from '../icons/NextBtnOutlineIcon';
import PlayIconFilled from '../icons/PlayOutlineIconFilled';
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
/* --------------------------------------------- */
import PlayerSeek from '../player/PlayerSeek';
import useAnalytics from '../../containers/useAnalytics'
import { chromecastConfig } from '../../config'
import utils from '../../utils/utils'
/* --------------------------------------------- */
import useLoggly from '../../containers/useLoggly'
/* --------------------------------------------- */
import { updateProgressInDB } from '../../apis/useDetailsApi'
/* --------------------------------------------- */
import RewindIconFilled from '../icons/RewindIconFilled';
import NextBtnFilledIcon from '../icons/NextBtnFilledIcon';
import ForwardIconFilled from '../icons/ForwardIconFilled';
import VolumeIcon from '../icons/VolumeIcon';
import VolumeIconFilled from '../icons/VolumeIconFilled';
import SubtitleIconFilled from '../icons/SubtitleIconFilled';
/* --------------------------------------------- */
import Spinner from '../Spinner';
/* --------------------------------------------- */
// ChromeCastMiniController component is used to handle chromecast sender event handlings.
const ChromeCastMiniController = () => {
    const [isSDKLoading] = useScript({ src: 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1', checkForExisting: true })
    /* --------------------------------------------- */
    const [, setVolume] = useState(0)
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [allTracks, setAllTracks] = useState([])
    const [castDevice, setCastDevice] = useState('')
    const [mediaDetails, setMediaDetails] = useState({})
    const [seekTime, setSeekTime] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [currentTimeFormatted, updateCurrentTimeFormatted] = useState('0:00')
    const [durationFormatted, updateDurationFormatted] = useState('0:00')
    const [activeTrack, setActiveTrack] = useState({})
    const [nextEpisodeNumber, setNextEpisodeNumber] = useState('')
    const [nextEpisodeTitle, setNextEpisodeTitle] = useState('')
    const [nextEpisodeUid, setNextEpisodeUid] = useState('')
    const [remotePlayer, setRemotePlayer] = useState({})
    const [isExpaned, updateIsExpaned] = useState(false)
    const [isShowPlayIcon, showPlayIcon] = useState(false)
    const [isShowMuteIcon, showMuteIcon] = useState(false)
    const [isShowBingePopup, setIsShowBingePopup] = useState(false)
    const [isShowController, setShowController] = useState(false)
    const [currentSubtitle, updateCurrentSubtitle] = useState('none')
    const [remotePlayerController, setRemotePlayerController] = useState({})
    const [isShowSubTitleSelector, setIsShowSubTitleSelector] = useState(false)
    const [castConfig, setCastConfig] = useState({})
    const [isShowNext, setIsShowNext] = useState(false)
    const [playerStartTime] = useState(Date.now())
    const [isPlayerReady, setPlayerReady] = useState(false)
    const [isBuffering, setBuffering] = useState(false)
    const [isShowLoader, setIsShowLoader] = useState(false)
    const [isLivePlayer, setIsLivePlayer] = useState(false);
    // const [isPlayerIdleState, setPlayerIdleState] = useState(false)
    const [bingePopUpStayClosed, setBingePopUpStayClosed] = useState('INITIAL');
    // const [isPlayerInitialState, setPlayerInitialState] = useState(true);
    // const [isLoaderDisabled, setLoaderDisabled] = useState(false);
    /* --------------------------------------------- */
    const { sendAnalytics } = useAnalytics()
    /* --------------------------------------------- */
    const currentTimeRef = useRef(currentTime)
    const durationRef = useRef(duration)
    const mediaDetailsRef = useRef(mediaDetails)
    const progressRef = useRef()
    const prevPlayingState = useRef(false)
    const nextEpisodeRef = useRef({})
    const isScrubbingRef = useRef(false)
    const mediaIdRef = useRef('')
    const isMediaLoadedRef = useRef(false)
    /* --------------------------------------------- */
    const { log } = useLoggly()
    /* --------------------------------------------- */
    const { isCastConnectedStatus, castData, dispatch, isAutoplay: globalAutoplay, userToken, userInfo, isAuthenticated } = useContext(GlobalContext)
    /* --------------------------------------------- */
    const playerFeature = useSelector(store => store.featuresReducers.playerFeature)
    const playerTheme = useSelector(store => store.themeConfigReducers.playerTheme)
    const appMessages = useSelector(store => store.appConfigReducers.appMessages)
    const appConfig = useSelector(store => store.appConfigReducers.appConfig)
    /* --------------------------------------------- */
    const castDataRef = useRef(castData)
    /* --------------------------------------------- */
    useEffect(() => { castDataRef.current = castData }, [castData])
    /* --------------------------------------------- */
    /**
     * It: Initializes chromecast
     */
    useEffect(() => {
        if (isSDKLoading) return
        if (!chromecastConfig.id) return
        /* --------------------------------------------- */
        window.__onGCastApiAvailable = isAvailable => {
            if (isAvailable) {
                const cast = window.cast
                const chrome = window.chrome
                /* --------------------------------------------- */
                if (cast && chrome) {
                    const options = {};
                    options.receiverApplicationId = chromecastConfig.id;
                    options.autoJoinPolicy = chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;
                    cast.framework.CastContext.getInstance().setOptions(options);
                    const remotePlayer = new cast.framework.RemotePlayer()
                    const remotePlayerController = new cast.framework.RemotePlayerController(remotePlayer);
                    setRemotePlayer(remotePlayer);
                    setRemotePlayerController(remotePlayerController);
                }
            }
        };
    }, [isSDKLoading])
    /* --------------------------------------------- */
    useEffect(() => {
        currentTimeRef.current = currentTime
    }, [currentTime])
    /* --------------------------------------------- */
    useEffect(() => {
        durationRef.current = duration
    }, [duration])
    /* --------------------------------------------- */
    useEffect(() => {
        mediaDetailsRef.current = mediaDetails
    }, [mediaDetails])
    /* --------------------------------------------- */
    useEffect(() => {
    }, [playerTheme.castPlayerTheme])
    /* --------------------------------------------- */
    useEffect(() => {
    }, [playerTheme.castMiniTheme])
    /* --------------------------------------------- */
    useEffect(() => {
        setSeekTime(currentTime)
    }, [currentTime])
    /* --------------------------------------------- */
    /**
     * It: Formats currentTime & duration and upate them in the state.
     */
    useEffect(() => {
        if (utils.isNotNull(seekTime) && !isNaN(seekTime)) {
            updateCurrentTimeFormatted(
                utils.toHHMMSS(seekTime)
            )
        }
        if (utils.isNotNull(duration) && !isNaN(duration)) {
            updateDurationFormatted(
                utils.toHHMMSS(duration)
            )
        }
    }, [seekTime, duration])
    /* --------------------------------------------- */
    /**
     * It: Sets castConfig in the state
     */
    useEffect(() => {
        if (playerFeature.castConfig) {
            setCastConfig(playerFeature.castConfig)
        }

        // if (utils.isNull(playerFeature.castConfig, 'theme', 0, 'fields')) return
        // const {
        //   config: rConfig,
        //   componentStyles: rStyle,
        //   componentTheme: rTheme
        // } = hydratePageComponent(playerFeature.castConfig, { contentType: type })
        // const railInfo = {
        //   ...rConfig,
        //   style: rStyle[0],
        //   componentTheme: rTheme
        // }

    }, [playerFeature.castConfig])
    /* --------------------------------------------- */
    /**
     * It: Sets listeners.
     */
    useEffect(() => {
        if (Object.keys(remotePlayerController).length === 0) return
        /* --------------------------------------------- */
        const cast = window.cast || {}
        /* --------------------------------------------- */
        const handleCastStateChange = (arg) => {
            const { value = false } = arg
            if (value) {
                dispatch({ type: "CAST_CONNECTED" })
                const castSession = cast.framework.CastContext.getInstance().getCurrentSession();
                setCastDevice(castSession.getCastDevice().friendlyName)
            } else {
                dispatch({ type: "CAST_DISCONNECTED" })
                dispatch({ type: "CAST_DATA", data: {} })
            }
        }
        /* --------------------------------------------- */
        const handlePlayerStateChange = (arg) => {
            const { value = '' } = arg
            if (value === "IDLE") {
              // setPlayerIdleState(true)
            } else {
              // setPlayerIdleState(false)
              setBuffering(value === 'BUFFERING')
            }

        }
        /* --------------------------------------------- */
        const handleMediaLoaded = (arg) => {
            const { value = false } = arg
            isMediaLoadedRef.current = value
            setPlayerReady(value)
            if (!value) {
                const progress = (currentTimeRef.current || 0) * 1000
                const duration = (durationRef.current || 0) * 1000
                const dataSet = (mediaDetailsRef.current || {}).dataSet || {}
                const details = { ...dataSet, progress, duration }
                updateProgressInDB(details)
            }
        }
        /* --------------------------------------------- */
        const handlePlayPause = (arg) => {
            const { value = false } = arg
            if (value) {
                showPlayIcon(true)
            } else {
                showPlayIcon(false)
            }
        }
        /* --------------------------------------------- */
        const handleMuteUnmute = (arg) => {
            const { value = false } = arg
            if (value) {
                showMuteIcon(true)
            } else {
                showMuteIcon(false)
            }
        }
        /* --------------------------------------------- */
        const handleCurrentTimeChange = (arg) => {
            const { value } = arg
            const isMediaLoaded = isMediaLoadedRef.current
            if (!isScrubbingRef.current && isMediaLoaded) setCurrentTime(value)
        }
        /* --------------------------------------------- */
        const handleDurationTimeChange = (arg) => {
            const { value = 0 } = arg
            const isMediaLoaded = isMediaLoadedRef.current
            if (isMediaLoaded) setDuration(value)
        }
        /* --------------------------------------------- */
        const handleVolumeChange = (arg) => {
            const { value = 0 } = arg
            setVolume(value)
        }
        /* --------------------------------------------- */
        /**
         * It: Sets title, subtitle, image & subtitleTracks.
         */
        const handleMediaInfoChange = (arg) => {
            if(arg.value && arg.value.streamType !== "NONE") {
                setShowController(true)
                setIsShowLoader(false)
            } else {
                setShowController(false)
                setIsShowLoader(true)
            }
            /* --------------------------------------------- */
            const { value = {} } = arg || {}
            const { metadata = {}, tracks = [], contentId = '' } = value || {}
            const { title = '', subtitle = '', images = [] } = metadata || {}
            setTitle(title || '')
            setSubtitle(subtitle || '')
            /* --------------------------------------------- */
            mediaIdRef.current = contentId
            /* --------------------------------------------- */
            let largeChromecastImg = document.getElementById('largeChromecastImg')
            let smallChromecastImg = document.getElementById('smallChromecastImg')
            let smallImg = utils.getCloserMatchImage(240, 135, (images || []))
            if (smallImg && !isShowBingePopup && smallChromecastImg) smallChromecastImg.src = smallImg.url
            let largeImg = utils.getCloserMatchImage(1280, 720, (images || []))
            if (largeImg && largeChromecastImg) largeChromecastImg.src = largeImg.url
            else if (smallImg && largeChromecastImg) largeChromecastImg.src = smallImg.url
            /* --------------------------------------------- */
            let allSubtitles = [];
            (tracks || []).map((track) => {
                const { language, type } = track || {}
                if (type === 'TEXT' && language) {
                    for (let i = 0; i < allSubtitles.length; i++) {
                        if (allSubtitles[i].language === language) return null
                    }
                    allSubtitles.push(track)
                }
                return null
            })
            if (allSubtitles.length > 0) allSubtitles = [{ language: 'none' }, ...allSubtitles]
            setAllTracks(allSubtitles)
            if(isShowController){
                setIsShowLoader(false)
            }
        }
        /* --------------------------------------------- */
        remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
            handleCastStateChange
        )
        /* --------------------------------------------- */
        remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
            handlePlayerStateChange
        )
        /* --------------------------------------------- */
        remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
            handleMediaLoaded
        )
        /* --------------------------------------------- */
        remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
            handlePlayPause
        )
        /* --------------------------------------------- */
        remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
            handleMuteUnmute
        )
        /* --------------------------------------------- */
        remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
            handleCurrentTimeChange
        )
        /* --------------------------------------------- */
        remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.DURATION_CHANGED,
            handleDurationTimeChange
        )
        /* --------------------------------------------- */
        remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
            handleVolumeChange
        )
        /* --------------------------------------------- */
        remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
            handleMediaInfoChange
        )
        /* --------------------------------------------- */
        return () => {
            remotePlayerController.removeEventListener(
                cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
                handleCastStateChange
            )
            /* --------------------------------------------- */
            remotePlayerController.removeEventListener(
                cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
                handlePlayerStateChange
            )
            /* --------------------------------------------- */
            remotePlayerController.removeEventListener(
                cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
                handleMediaLoaded
            )
            /* --------------------------------------------- */
            remotePlayerController.removeEventListener(
                cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
                handlePlayPause
            )
            /* --------------------------------------------- */
            remotePlayerController.removeEventListener(
                cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
                handleMuteUnmute
            )
            /* --------------------------------------------- */
            remotePlayerController.removeEventListener(
                cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
                handleCurrentTimeChange
            )
            /* --------------------------------------------- */
            remotePlayerController.removeEventListener(
                cast.framework.RemotePlayerEventType.DURATION_CHANGED,
                handleDurationTimeChange
            )
            /* --------------------------------------------- */
            remotePlayerController.removeEventListener(
                cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
                handleVolumeChange
            )
            /* --------------------------------------------- */
            remotePlayerController.removeEventListener(
                cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
                handleMediaInfoChange
            )
        }
    }, [
        dispatch,
        isShowBingePopup,
        remotePlayerController,
        duration,
        playerFeature.progressSaveInterval,
        remotePlayer.currentTime,
        isShowController
    ])
    /* --------------------------------------------- */
    useEffect(() => {
        /**
         * Listening to globalAutoplay setting and sending a message to receiver.
         */
        const cast = window.cast || {}
        /* --------------------------------------------- */
        if (!isCastConnectedStatus || !isAuthenticated) return
        if (Object.keys(remotePlayer).length === 0) return
        // /* --------------------------------------------- */
        const castSession = cast.framework.CastContext.getInstance().getCurrentSession();
        castSession.sendMessage('urn:x-cast:autoplay', globalAutoplay?.toString());
    }, [
        globalAutoplay,
        remotePlayer,
        isCastConnectedStatus,
        isAuthenticated
    ])
    /* --------------------------------------------- */
    /**
     * It: Sets message listener.
     */
    useEffect(() => {
        const cast = window.cast || {}
        const chrome = window.chrome || {}
        /* --------------------------------------------- */
        if(!isAuthenticated) return
        if (!isCastConnectedStatus || !isAuthenticated) return
        if (Object.keys(remotePlayer).length === 0) return
        if (!castData || Object.keys(castData).length === 0) return
        /* --------------------------------------------- */
        const { mediaId } = castData
        /* --------------------------------------------- */
        /** do not start from beginning when the same media is already being casted */
        if (mediaIdRef.current === mediaId) return
        /* --------------------------------------------- */
        const mediaInfo = new chrome.cast.media.MediaInfo(mediaId);
        /* --------------------------------------------- */
        const analyticsData = {
          contentOfferId: mediaDetails?.dataSet?.contentOfferId || '',
          playerStartTime: playerStartTime
        }
        mediaInfo.customData = {...castData, ...analyticsData }
        /* --------------------------------------------- */
        const request = new chrome.cast.media.LoadRequest(mediaInfo);
        const castSession = cast.framework.CastContext.getInstance().getCurrentSession();
        /* --------------------------------------------- */
        setShowController(false)
        setIsShowLoader(true)
        castSession.loadMedia(request)

        // if (isPlayerInitialState) {
        //   setPlayerInitialState(false)
        // } else if (isLoaderDisabled) {
        //   setLoaderDisabled(false)
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        castData,
        remotePlayer,
        isCastConnectedStatus,
    ])
    /* --------------------------------------------- */
    useEffect(() => {
        const cast = window.cast || {}
        if (!cast || Object.keys(cast).length === 0) return
        if (!isCastConnectedStatus || !isAuthenticated) return
        /* --------------------------------------------- */
        const messageListener = (namespace, message) => {
            let data = JSON.parse(message)
            if (data.type === 'showBingePopup') {
                let smallChromecastImg = document.getElementById('smallChromecastImg')
                let smallImg = utils.getCloserMatchImage(240, 135, data.nextEpisode.images)
                let bingWatchImg = document.getElementById('bingwatch-img')
                let bingImg = utils.getCloserMatchImage(320, 180, data.nextEpisode.images)
                setNextEpisodeNumber((nextEpisodeNumber) => {
                    if (nextEpisodeNumber !== data.nextEpisode.episodeNumber) {
                        if (smallChromecastImg && smallImg && smallImg.url) smallChromecastImg.src = smallImg.url
                        if (bingWatchImg && bingImg && bingImg.url) bingWatchImg.src = bingImg.url
                        return data.nextEpisode.episodeNumber
                    }
                    else return nextEpisodeNumber
                })

                setNextEpisodeTitle((nextEpisodeTitle) => {
                    if (nextEpisodeTitle !== data.nextEpisode.title) return data.nextEpisode.title
                    else return nextEpisodeTitle
                })

                if (bingePopUpStayClosed === 'INITIAL') {
                    setIsShowBingePopup(true);
                }
            }
            else if (data.type === 'mediaDetails') {
                setMediaDetails(data)
                setBingePopUpStayClosed('INITIAL');
                setIsShowBingePopup(false)
            }
            // else if (data.type === 'showError') {
            //     setLoaderDisabled(true)
            // }
            else if (data.type === 'hideBingePopup') {
                setIsShowBingePopup(false)
            }
            else if (data.type === 'showNext') {
                setIsShowNext(true)
                setNextEpisodeUid(data.nextEpisode.uid)
                nextEpisodeRef.current = data.nextEpisode || {}
                const details = (mediaDetailsRef.current || {}).dataSet || {}
                nextEpisodeRef.current.seriesUid = details.seriesId || ''
                nextEpisodeRef.current.seriesTitle = details.seriesTitle || ''
            }
            else {
                setIsShowBingePopup(false)
            }
        }
        /* --------------------------------------------- */
        const castSession = cast.framework.CastContext.getInstance().getCurrentSession();
        castSession.addMessageListener('urn:x-cast:com.videotron.illico', messageListener)
        /* --------------------------------------------- */
        return () => {
            castSession.removeMessageListener('urn:x-cast:com.videotron.illico', messageListener)
        }
    }, [isCastConnectedStatus, bingePopUpStayClosed, isAuthenticated])
    /* --------------------------------------------- */
    const binchCloseHandle = () => {
        setIsShowBingePopup(false)
        setBingePopUpStayClosed('REMAIN_CLOSED');
    }
    /* --------------------------------------------- */
    /**
     * It: Updates progress bar.
     */
    useEffect(() => {
        if (!currentTime || !duration) return
        /* --------------------------------------------- */
        const seekPos = (currentTime * 100) / duration
        /* --------------------------------------------- */
        if (progressRef.current) {
            progressRef.current.style.width = `${seekPos}%`
        }
    }, [
        duration,
        currentTime,
    ])
    /* --------------------------------------------- */
    /**
     * It: Hides next button for movies & trailer.
     */
    useEffect(() => {
        if (mediaDetails.type !== 'series') {
            setIsShowNext(false)
        }
        if (mediaDetails.type === 'live' ||  mediaDetails?.dataSet?.type === 'live') {
            setIsLivePlayer(true)
        }
        else {
            setIsLivePlayer(false)
        }
    }, [mediaDetails])
    /* --------------------------------------------- */
    /**
     * It: Hides next button for movies.
     */
    useEffect(() => {
        if (!isCastConnectedStatus || !isAuthenticated) return
        /* --------------------------------------------- */
        const cast = window.cast
        const chrome = window.chrome
        let activeTrackIds = [];
        if (utils.isNotNull(activeTrack, 'trackId')) activeTrackIds = [activeTrack.trackId];
        const castSession = cast.framework.CastContext.getInstance().getCurrentSession();
        const sessionId = castSession.getSessionId()
        const mediaSession = castSession.getMediaSession()
        /* --------------------------------------------- */
        if (!sessionId || !mediaSession) return
        /* --------------------------------------------- */
        const media = new chrome.cast.media.Media(sessionId, mediaSession.mediaSessionId)
        const tracksInfoRequest = new chrome.cast.media.EditTracksInfoRequest(activeTrackIds);
        media.editTracksInfo(tracksInfoRequest)
    }, [
        activeTrack,
        isCastConnectedStatus,
        isAuthenticated
    ])
    /* --------------------------------------------- */
    const handlePlayClick = useCallback(() => {

        if (Object.keys(remotePlayerController).length === 0) return
        /* --------------------------------------------- */
        showPlayIcon(false)
        /* --------------------------------------------- */
        if (remotePlayer.isPaused) {
            remotePlayerController.playOrPause();
        }
        /* --------------------------------------------- */
        sendAnalytics({
            type: '_content.playback',
            action: 'resume',
            attr: {
                start_time: playerStartTime,
                content_offer_id: mediaDetails.contentOfferId,
                content_id: mediaDetails.uid,
                content_stream_id: mediaDetails.mediaGuid,
                content_title: mediaDetails.title,
                content_type: mediaDetails.type,
                content_duration: duration,
                progress: currentTime,
                playback_mode: 'chromecast'
            }
        })
        /* --------------------------------------------- */
        log({
            type: 'Info', attr: {
                module: 'Cast',
                cast_info: {
                    playback_action: 'cast_resume',
                    content_offer_id: mediaDetails.contentOfferId,
                    content_id: mediaDetails.uid,
                    content_stream_id: mediaDetails.mediaGuid,
                    content_title: mediaDetails.title,
                    content_type: mediaDetails.type,
                    start_time: Date.now(),
                    content_duration: duration,
                    progress: currentTime,
                    playback_mode: 'chromecast'
                }
            }
        })
    }, [
        playerStartTime,
        currentTime,
        duration,
        mediaDetails,
        log,
        sendAnalytics,
        remotePlayer.isPaused,
        remotePlayerController
    ])
    /* --------------------------------------------- */
    /**
     * When: On subtitle selector Click.
     * It: Toggles subtitle selector.
     */
    const toggleSubTitleSelector = () => {
        if (isShowSubTitleSelector) {
            setIsShowSubTitleSelector(false)
        } else setIsShowSubTitleSelector(true)
    }
    /* --------------------------------------------- */
    /**
     * When: On Pause Click.
     * It: Toggles pause icon.
     * It: Sends pause analytics event.
     * It: Sends pause loggly log.
     */
    const handlePauseClick = useCallback(() => {
        if (Object.keys(remotePlayerController).length === 0) return
        /* --------------------------------------------- */
        showPlayIcon(true)
        /* --------------------------------------------- */
        if (!remotePlayer.isPaused) {
            remotePlayerController.playOrPause();
        }

        /* --------------------------------------------- */
        sendAnalytics({
            type: '_content.playback',
            action: 'pause',
            attr: {
                start_time: playerStartTime,
                content_offer_id: mediaDetails.contentOfferId,
                content_id: mediaDetails.uid,
                content_stream_id: mediaDetails.mediaGuid,
                content_title: mediaDetails.title,
                content_type: mediaDetails.type,
                content_duration: duration,
                progress: currentTime,
                playback_mode: 'chromecast'
            }
        })
        /* --------------------------------------------- */
        log({
            type: 'Info', attr: {
                module: 'Cast',
                cast_info: {
                    playback_action: 'cast_pause',
                    content_offer_id: mediaDetails.contentOfferId,
                    content_id: mediaDetails.uid,
                    content_stream_id: mediaDetails.mediaGuid,
                    content_title: mediaDetails.title,
                    content_type: mediaDetails.type,
                    start_time: Date.now(),
                    content_duration: duration,
                    progress: currentTime,
                    playback_mode: 'chromecast'
                }
            }
        })

    }, [
        playerStartTime,
        currentTime,
        duration,
        mediaDetails,
        sendAnalytics,
        log,
        remotePlayer.isPaused,
        remotePlayerController
    ])
    /* --------------------------------------------- */
    /**
     * When: On Mute Click.
     * It: Sets mute icon state.
     * It: Triggers mute in remote player.
     */
    const handleMuteClick = useCallback(() => {
        if (Object.keys(remotePlayerController).length === 0) return
        /* --------------------------------------------- */
        showMuteIcon(false)
        /* --------------------------------------------- */
        if (remotePlayer.isMuted) {
            remotePlayerController.muteOrUnmute();
        }
    }, [
        remotePlayer.isMuted,
        remotePlayerController
    ])
    /* --------------------------------------------- */
    /**
     * When: On up arrow click.
     * It: toggles mini controller.
     */
    const upArrowOnClick = () => {
        if (!isExpaned) updateIsExpaned(true)
    }
    /* --------------------------------------------- */
    /**
     * When: On back button click.
     * It: toggles expanded controller.
     */
    const backBtnhandler = () => {
        if (isExpaned) updateIsExpaned(false)
    }
    /* --------------------------------------------- */
    /**
     * When: On Next button click.
     * It: Dispatches event for next episode.
     */
    const nextBtnOnClick = () => {
        const lang = getAppLanguageWithoutRegion()
        setBingePopUpStayClosed("INITIAL")
        dispatch({
            type: "CAST_DATA", data: {
                baseUrl: appConfig.baseUrl,
                mediaId: nextEpisodeUid,
                token: userToken || '',
                lang,
                autoplay: globalAutoplay,
                castConfigPlaybackFormat: playerFeature && playerFeature.castConfig && playerFeature.castConfig.playbackAssetFormat,
                progress: 0,
                userId: userInfo.userId || '',
                userType: userInfo.customerType || '',
                userEmailHash: userInfo.emailHash,
                userSource: userInfo.source
            }
        })
    }
    /* --------------------------------------------- */
    /**
     * When: On Subtitle change.
     * It: Sets active subtitle in a state.
     * It: Toggles subtitle selector state.
     */
    const subTitleChangeHandle = (track) => {
        setActiveTrack(track)
        updateCurrentSubtitle(track.language)
        setIsShowSubTitleSelector(false)
    }
    /* --------------------------------------------- */
    /**
     * When: On UnMute Click.
     * It: Sets mute icon state.
     * It: Triggers unmute in remote player.
     */
    const handleUnmuteClick = useCallback(() => {
        if (Object.keys(remotePlayerController).length === 0) return
        /* --------------------------------------------- */
        showMuteIcon(true)
        /* --------------------------------------------- */
        if (!remotePlayer.isMuted) {
            remotePlayerController.muteOrUnmute();
        }
    }, [
        remotePlayer.isMuted,
        remotePlayerController
    ])
    /* --------------------------------------------- */
    /**
     * When: On Rewind Click.
     * It: Seeks in remote player.
     * It: Sends seek analytic event.
     * It: Sends seek loggly log.
     */
    const handleRewindClick = useCallback(() => {
        if (Object.keys(remotePlayerController).length === 0) return
        /* --------------------------------------------- */
        let nextTime = currentTime - castConfig.rewDuration
        /* --------------------------------------------- */
        if (nextTime < 0) nextTime = 0
        /* --------------------------------------------- */
        remotePlayer.currentTime = nextTime;
        remotePlayerController.seek();

        /* --------------------------------------------- */
        sendAnalytics({
            type: '_content.playback',
            action: 'seek',
            attr: {
                start_time: playerStartTime,
                content_offer_id: mediaDetails.contentOfferId,
                content_id: mediaDetails.uid,
                content_stream_id: mediaDetails.mediaGuid,
                content_title: mediaDetails.title,
                content_type: mediaDetails.type,
                content_duration: duration,
                progress: currentTime,
                playback_mode: 'chromecast'
            }
        })
        /* --------------------------------------------- */
        log({
            type: 'Info', attr: {
                module: 'Cast',
                cast_info: {
                    playback_action: 'cast_seek_rewind',
                    content_offer_id: mediaDetails.contentOfferId,
                    content_id: mediaDetails.uid,
                    content_stream_id: mediaDetails.mediaGuid,
                    content_title: mediaDetails.title,
                    content_type: mediaDetails.type,
                    start_time: Date.now(),
                    content_duration: duration,
                    progress: currentTime,
                    playback_mode: 'chromecast'
                }
            }
        })

    }, [
        playerStartTime,
        currentTime,
        duration,
        mediaDetails,
        sendAnalytics,
        castConfig.rewDuration,
        remotePlayerController,
        remotePlayer.currentTime,
        log
    ])
    /* --------------------------------------------- */
    /**
     * When: On Forward Click.
     * It: Seeks in remote player.
     * It: Sends seek analytic event.
     * It: Sends seek loggly log.
     */
    const handleForwardClick = useCallback(() => {
        if (Object.keys(remotePlayerController).length === 0) return
        /* --------------------------------------------- */
        let nextTime = currentTime + castConfig.fwdDuration
        /* --------------------------------------------- */
        if (nextTime > duration) nextTime = duration
        /* --------------------------------------------- */
        remotePlayer.currentTime = nextTime;
        remotePlayerController.seek();
        /* --------------------------------------------- */
        sendAnalytics({
            type: '_content.playback',
            action: 'seek',
            attr: {
                start_time: playerStartTime,
                content_offer_id: mediaDetails.contentOfferId,
                content_id: mediaDetails.uid,
                content_stream_id: mediaDetails.mediaGuid,
                content_title: mediaDetails.title,
                content_type: mediaDetails.type,
                content_duration: duration,
                progress: currentTime,
                playback_mode: 'chromecast'
            }
        })
        /* --------------------------------------------- */
        log({
            type: 'Info', attr: {
                module: 'Cast',
                cast_info: {
                    playback_action: 'cast_seek_forward',
                    content_offer_id: mediaDetails.contentOfferId,
                    content_id: mediaDetails.uid,
                    content_stream_id: mediaDetails.mediaGuid,
                    content_title: mediaDetails.title,
                    content_type: mediaDetails.type,
                    start_time: Date.now(),
                    content_duration: duration,
                    progress: currentTime,
                    playback_mode: 'chromecast'
                }
            }
        })

    }, [
        playerStartTime,
        currentTime,
        duration,
        log,
        mediaDetails,
        sendAnalytics,
        castConfig.fwdDuration,
        remotePlayerController,
        remotePlayer.currentTime
    ])
    /* --------------------------------------------- */
    /**
     * When: On Slide Start.
     * It: Updates scrubbing state.
     * It: Triggers pause.
     */
    const handleSlideStart = useCallback(() => {
        isScrubbingRef.current = true
        prevPlayingState.current = !isShowPlayIcon
        handlePauseClick()
    }, [isShowPlayIcon, handlePauseClick])
    /* --------------------------------------------- */
    /**
     * When: On Slide End.
     * It: Triggers seek in remote player.
     * It: Updates current time state.
     * It: Triggers play.
     */
    const handleSlideEnd = useCallback(([toTime]) => {
        setIsShowBingePopup(false)
        if (Object.keys(remotePlayerController).length === 0) return
        /* --------------------------------------------- */
        remotePlayer.currentTime = toTime < 1 ? 1 : toTime;
        remotePlayerController.seek();
        /* --------------------------------------------- */
        setCurrentTime(toTime)
        /* --------------------------------------------- */
        if (prevPlayingState.current) handlePlayClick()
        isScrubbingRef.current = false
    }, [
        remotePlayerController,
        remotePlayer.currentTime,
        handlePlayClick
    ])
    /* --------------------------------------------- */
    /**
     * When: On Sliding.
     * It: Updates seek time state.
     */
    const handleSlideUpdate = useCallback(([seekTime]) => {
        setSeekTime(seekTime)
    }, [])

    /* -------------------------------------------- */
    // Showing loader in case the media is changed. This is added to remove the 2006 error on receiver side
    if( isCastConnectedStatus && isShowLoader) return <Spinner size={40} IsLoading={true} />
    // if(isCastConnectedStatus && !isPlayerInitialState && !isPlayerReady && !isLoaderDisabled && (duration && parseInt(duration) !== parseInt(currentTime))) {
    //   return <Spinner size={40} IsLoading={true} />
    // }
    /* --------------------------------------------- */
    if (!isCastConnectedStatus || !isShowController || !isAuthenticated) return <></>
    /* --------------------------------------------- */
    return (
        <ChromeCastWrapper {...{ playerTheme }} className='chrome-cast-controller'>
            <ChromecastPlayerTheme {...{ playerTheme }} >
                <div className={`chromecast-expanded-controler-wrapper ${isExpaned ? 'chromecast-expanded-controler-wrapper---open' : ''}`}>
                    <Spinner size={40} IsLoading={isBuffering} />
                    <div className='controler-content'>
                        <div className='top-control'>
                            <div className='title-block'>
                                <div className='back-btn' onClick={backBtnhandler}><GoBackArrowIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 20, height: 20 }} /></div>
                                <div className='title'>
                                    <p>{subtitle}</p>
                                    <h2>{title}</h2>
                                </div>
                            </div>
                            <div className='actions'>
                                <div className="cast-action">
                                    <google-cast-launcher style={{ display: 'block', width: 28, height: 28, cursor: 'pointer' }}></google-cast-launcher>
                                </div>
                                {isPlayerReady && castConfig.subtitleSelector && !isLivePlayer && allTracks.length > 0 &&
                                    <div onClick={toggleSubTitleSelector}>
                                        <p className='icon-label'>{appMessages.label_player_subtitles}</p>
                                        <div className="player-icon-container">
                                            <SubtitleIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 36, height: 36 }} className="player-icon" />
                                            <SubtitleIconFilled color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 36, height: 36 }} className="playerfill-icon" />
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <img id='largeChromecastImg' src='' alt="" />
                        { globalAutoplay ? (
                        <div className={`binch-container-wrapper ${isShowBingePopup ? 'binch-container-wrapper---open' : ''}`}>
                            <BingeContainer theme={playerTheme?.castSelectorTheme} className='binch__content-wrapper binch-container'>
                                <div className='binch__header'>
                                    <p>{appMessages.label_binge_up_next_on} {castDevice}</p>
                                    <div onClick={binchCloseHandle}>
                                        <CloseIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 15, height: 15 }} />
                                    </div>
                                </div>
                                <BingeImageWrapper>
                                    <img id='bingwatch-img' src='' alt='bingewatch img' />
                                </BingeImageWrapper>
                                <div className='binch__content'>
                                    <div className='binch-content__text'>
                                        <p>{appMessages.label_details_episode} {nextEpisodeNumber}</p>
                                        <h3>{nextEpisodeTitle}</h3>
                                    </div>
                                    <div className='binch-content__icon' onClick={nextBtnOnClick}>
                                        <PlayIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 28, height: 32 }} />
                                    </div>
                                </div>
                            </BingeContainer>
                        </div>) : null
}
                        <div className='bottom-control'>
                            {castDevice && <p className='cast-device'>{appMessages.label_chromecast_casting_to} {castDevice}</p>}
                            {!isLivePlayer && isPlayerReady && castConfig.seekBar &&
                                <div className='track-wrapper'>
                                    <div className='seek__track'>
                                        <PlayerSeek
                                            resetBingePopOnClick={() => setTimeout(() => { setBingePopUpStayClosed("INITIAL")}, 1500) }
                                            isShowThumbnail={false}
                                            onSlideStart={handleSlideStart}
                                            onSlideEnd={handleSlideEnd}
                                            onUpdate={handleSlideUpdate}
                                            trackColor={utils.isNotNull(playerTheme, 'castPlayerTheme', 'header', 'text', 'secondary') && playerTheme.castPlayerTheme.header.text.secondary}
                                            seekBarColor={utils.isNotNull(playerTheme, 'castPlayerTheme', 'body', 'background') && playerTheme.castPlayerTheme.body.background}
                                            currentPos={(currentTime || 0)}
                                            duration={(duration || 0)}
                                            isFromCast={true}
                                        />
                                    </div>
                                </div>}
                            {isPlayerReady && <div className='bottom-action'>
                                <div className='timer'>
                                {!isLivePlayer && <p><span>{utils.trimLeadingZeroesFromHHMMSS(currentTimeFormatted)}</span> / <span >{utils.trimLeadingZeroesFromHHMMSS(durationFormatted)}</span></p>}
                                </div>
                                <div className='control'>
                                    {castConfig.fwdButton && !isLivePlayer &&
                                        <div onClick={handleRewindClick} className="player-icon-container">
                                            {/* <RewindIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} text={castConfig.rewDuration.toString().length === 1 ? `0${castConfig.rewDuration}` : castConfig.rewDuration} style={{ width: 40, height: 40 }} /> */}
                                            <RewindIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} text={castConfig.rewDuration.toString().length === 1 ? `0${castConfig.rewDuration}` : castConfig.rewDuration} style={{ width: 40, height: 40 }} className="player-icon" />
                                            <RewindIconFilled color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} text={castConfig.rewDuration.toString().length === 1 ? `0${castConfig.rewDuration}` : castConfig.rewDuration} style={{ width: 40, height: 40 }} className="playerfill-icon" />
                                        </div>}
                                    {
                                        isShowPlayIcon && castConfig.playPauseButton &&
                                        <div onClick={handlePlayClick} className="player-icon-container">
                                            <PlayIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 32, height: 35 }} className="player-icon" />
                                            <PlayIconFilled color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 32, height: 35 }} className="playerfill-icon" />
                                        </div>
                                    }
                                    {
                                        !isShowPlayIcon && castConfig.playPauseButton &&
                                        <div onClick={handlePauseClick} className="player-icon-container">
                                            <PauseIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 32, height: 35 }} className="player-icon" />
                                            <PauseIconFilled color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 32, height: 35 }} className="playerfill-icon" />
                                        </div>
                                    }
                                    {castConfig.rewButton && !isLivePlayer &&
                                        <div onClick={handleForwardClick} className="player-icon-container">
                                            <ForwardIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} text={castConfig.fwdDuration.toString().length === 1 ? `0${castConfig.fwdDuration}` : castConfig.fwdDuration} style={{ width: 40, height: 40 }} className="player-icon" />
                                            <ForwardIconFilled color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} text={castConfig.fwdDuration.toString().length === 1 ? `0${castConfig.fwdDuration}` : castConfig.fwdDuration} style={{ width: 40, height: 40 }} className="playerfill-icon" />
                                        </div>}

                                    {isShowNext && !isLivePlayer && <div onClick={nextBtnOnClick} className="player-icon-container">
                                        <NextBtnOutlineIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} next={appMessages.player_control_next} style={{ width: 33, height: 50 }} className="player-icon" />
                                        <NextBtnFilledIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} next={appMessages.player_control_next} style={{ width: 33, height: 50 }} className="playerfill-icon" />
                                    </div>}
                                </div>
                                {castConfig.muteButton &&
                                    <div className='action'>
                                        {
                                            isShowMuteIcon &&
                                            <div className='single' onClick={handleMuteClick}>
                                                <VolumeMuteOutlineIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 30, height: 30 }} />
                                            </div>
                                        }
                                        {
                                            !isShowMuteIcon &&
                                            <div className='single player-icon-container' onClick={handleUnmuteClick}>
                                                <VolumeIcon className="playerfill-icon" color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 30, height: 30 }} />
                                                <VolumeIconFilled className="player-icon" color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 30, height: 30 }} />
                                            </div>
                                        }
                                    </div>}
                            </div>}
                        </div>
                    </div>
                </div>
                <div className={`player-audio__list ${isShowSubTitleSelector ? 'player-audio__list---open' : ''}`}>
                    <Popup theme={playerTheme?.castSelectorTheme} className='player-audio__content-wrapper'>
                        <div className='player-audio__head'>
                            <p>{appMessages.label_player_language_options}</p>
                            <div className='player-audio__close' onClick={toggleSubTitleSelector}>
                                <CloseIcon color={playerTheme && playerTheme.castPlayerTheme && playerTheme.castPlayerTheme.header.accent.primary} style={{ width: 18, height: 18 }} />
                            </div>
                        </div>
                        <div className='player-audio__content-list-wrapper'>
                            <h3>{appMessages.label_player_subtitles}</h3>
                            <div className='player-audio__content'>
                                <ul>
                                    {
                                        allTracks.sort((b, a) => a.language.localeCompare(b.language)).map((item, i) => {
                                            const subTitleLanguage = `subtitle_${item.language}`
                                            const msg = (item.language === 'none') ? appMessages.subtitle_none : (appMessages[subTitleLanguage] || item.language)
                                            if (!msg) return null
                                            return <li className={`${currentSubtitle === item.language ? 'active' : ''}`} onClick={() => subTitleChangeHandle(item)} key={i}>{msg}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </Popup>
                </div>
            </ChromecastPlayerTheme>
            <ChromecastMiniTheme {...{ playerTheme }} className='chromecast-mini-wrapper'>
                <div className='chromecast-mini-wrapper-content'>
                    {!isLivePlayer && <div className='mini-progress-bar' ref={progressRef}></div>}
                    <div className="row seekTimerWrapper" style={{ height: '27px' }}>
                        {!isLivePlayer && isPlayerReady && <p className="seekTimer"><span>{utils.trimLeadingZeroesFromHHMMSS(utils.toHHMMSS(currentTime))}</span> / <span >{utils.trimLeadingZeroesFromHHMMSS(utils.toHHMMSS(duration))}</span></p>}
                        <div onClick={upArrowOnClick}><UpArrowIcon color={playerTheme && playerTheme.castMiniTheme && playerTheme.castMiniTheme.header.accent.primary} className="up-arrow" /></div>
                    </div>
                    <div className="row data-wrapper">
                        <div className='img-container'>
                            <img id='smallChromecastImg' src='' alt="" />
                        </div>
                        {isShowBingePopup ? <ChromecastMiniControlerDetails {...{ playerTheme }} className='details'>
                            <h2>{appMessages.label_binge_up_next_on} {castDevice}</h2>
                            <h1>{appMessages.label_details_episode} {nextEpisodeNumber}: {nextEpisodeTitle} </h1>
                        </ChromecastMiniControlerDetails>
                            :
                            <ChromecastMiniControlerDetails {...{ playerTheme }} className='details'>
                                <h2 >{subtitle}</h2>
                                <h1>{title}</h1>
                                {castDevice && <p>{appMessages.label_chromecast_casting_to} {castDevice}</p>}
                            </ChromecastMiniControlerDetails>
                        }
                        <div className="chromecast-mini-spinner">
                            <Spinner size={30} IsLoading={isBuffering} />
                        </div>
                        {(!isShowBingePopup) ? <div className='action'>
                            {
                                isPlayerReady && isShowPlayIcon && castConfig.playPauseButton &&
                                <div onClick={handlePlayClick} className='player-icon-container single'>
                                    <PlayIcon color={playerTheme && playerTheme.castMiniTheme && playerTheme.castMiniTheme.header.accent.primary} style={{ width: 24, height: 24 }} className="player-icon" />
                                    <PlayIconFilled color={playerTheme && playerTheme.castMiniTheme && playerTheme.castMiniTheme.header.accent.primary} style={{ width: 24, height: 24 }} className="playerfill-icon" />
                                </div>
                            }
                            {
                                isPlayerReady && !isShowPlayIcon && castConfig.playPauseButton &&
                                <div onClick={handlePauseClick} className='player-icon-container single'>
                                    <PauseIcon color={playerTheme && playerTheme.castMiniTheme && playerTheme.castMiniTheme.header.accent.primary} style={{ width: 24, height: 24 }} className="player-icon" />
                                    <PauseIconFilled color={playerTheme && playerTheme.castMiniTheme && playerTheme.castMiniTheme.header.accent.primary} style={{ width: 24, height: 24 }} className="playerfill-icon" />
                                </div>
                            }

                            {
                                isPlayerReady && isShowMuteIcon && castConfig.muteButton &&
                                <div className='single' onClick={handleMuteClick} >
                                    <VolumeMuteOutlineIcon color={playerTheme && playerTheme.castMiniTheme && playerTheme.castMiniTheme.header.accent.primary} style={{ width: 30, height: 30 }} />
                                </div>
                            }
                            {
                                isPlayerReady && !isShowMuteIcon && castConfig.muteButton &&
                                <div onClick={handleUnmuteClick} className='player-icon-container single'>
                                    <VolumeIcon className="playerfill-icon" color={playerTheme && playerTheme.castMiniTheme && playerTheme.castMiniTheme.header.accent.primary} style={{ width: 30, height: 30 }} />
                                    <VolumeIconFilled className="player-icon" color={playerTheme && playerTheme.castMiniTheme && playerTheme.castMiniTheme.header.accent.primary} style={{ width: 30, height: 30 }} />
                                </div>
                            }

                            <div className='single' id="castbutton">
                                <google-cast-launcher></google-cast-launcher>
                            </div>
                        </div>
                            : (isPlayerReady &&
                                <div className='action'>
                                    <div onClick={nextBtnOnClick} className='player-icon-container single'>
                                        <PlayIcon color={playerTheme && playerTheme.castMiniTheme && playerTheme.castMiniTheme.header.accent.primary} style={{ width: 24, height: 24 }} className="player-icon" />
                                        <PlayIconFilled color={playerTheme && playerTheme.castMiniTheme && playerTheme.castMiniTheme.header.accent.primary} style={{ width: 24, height: 24 }} className="playerfill-icon" />
                                    </div>
                                </div>)
                        }
                    </div>
                </div>
            </ChromecastMiniTheme>
        </ChromeCastWrapper>
    )
}

const ChromeCastWrapper = styled.div`
--connected-color:  ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castPlayerTheme', 'header', 'accent', 'primary') && playerTheme.castPlayerTheme.header.accent.primary};
--disconnected-color: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castPlayerTheme', 'header', 'accent', 'primary') && playerTheme.castPlayerTheme.header.accent.primary};

.binch__header  p{
    color:  ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castPlayerTheme', 'header', 'text', 'primary') && playerTheme?.castPlayerTheme?.header?.text?.primary};
}
`
/* --------------------------------------------- */
const ChromecastMiniControlerDetails = styled.div`
    h2 {
        color: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castMiniTheme', 'header', 'accent', 'primary') && playerTheme.castMiniTheme.header.accent.primary};
    }
    h1 {
        color: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castMiniTheme', 'header', 'text', 'secondary') && playerTheme.castMiniTheme.header.text.primary};
    }
    p{
        color: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castMiniTheme', 'header', 'accent', 'secondary') && playerTheme.castMiniTheme.header.text.tertiary};

    }
`
const ChromecastMiniTheme = styled.div`
        background: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castMiniTheme', 'body', 'background', 'primary') && playerTheme?.castMiniTheme?.body?.background?.primary};

    .chromecast-mini-wrapper{
        background: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castMiniTheme', 'body', 'background', 'primary') && playerTheme.castMiniTheme.body.background.primary};
        .row {
            p {
                ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castMiniTheme', 'body', 'text', 'primary') && playerTheme?.castMiniTheme?.body?.text?.primary};  
            }
        }
    }

    .mini-progress-bar{
        background : ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castPlayerTheme', 'body', 'accent', 'primary') && playerTheme?.castMiniTheme?.body?.accent?.primary};
    }

    .details{
            color:  ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castPlayerTheme', 'body', 'text', 'primary') && playerTheme.castMiniTheme.body.text.primary};
    }

    `
const ChromecastPlayerTheme = styled.div`
chromecast-expanded-controler-wrapper {
    background-color: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castPlayerTheme', 'body', 'background', 'primary') && playerTheme.castPlayerTheme.body.background.primary};
}
    .title{
        p{
            color: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castPlayerTheme', 'header', 'text', 'secondary') && playerTheme.castPlayerTheme.header.text.secondary};
        }
        h2{
            color: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castPlayerTheme', 'header', 'text', 'primary') && playerTheme.castPlayerTheme.header.text.primary};

        }
    }
    .top-control {
        p.icon-label {
            color: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castPlayerTheme', 'header', 'text', 'primary') && playerTheme.castPlayerTheme.header.text.primary};

        }
    }
    .bottom-control {
        p.cast-device {
            color: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castPlayerTheme', 'body', 'text', 'primary') && playerTheme?.castPlayerTheme?.body?.text?.primary};

        }
        .timer {
            p {
            color: ${({ playerTheme }) => utils.isNotNull(playerTheme, 'castPlayerTheme', 'body', 'text', 'primary') && playerTheme?.castPlayerTheme?.body?.text?.primary};

            }
        }
        .control {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`
const BingeContainer = styled.div`
background-color: ${({ theme: { body } }) => body && body?.background?.primary};
.binch__header {
background-color: ${({ theme: { header } }) => header && header?.background?.primary};
p {
    color: ${({ theme: { header } }) => header && header?.text?.primary};
}
}
.binch-content__text {
    p{
        color: ${({ theme: { body } }) => body && body?.text?.secondary};
    }
    h3{
        color: ${({ theme: { body } }) => body && body?.text?.primary};
    }
}
`
const BingeImageWrapper = styled.div`
    background: gray;
`
const Popup = styled.div`
background-color: ${({ theme: { body } }) => body && body?.background?.primary};
li{
    background-color: ${({ theme: { compositeStyle } }) => compositeStyle?.selectorStyle?.normal?.background};
    color: ${({ theme: { compositeStyle } }) => compositeStyle?.selectorStyle?.normal?.text};
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme: { compositeStyle } }) => compositeStyle?.selectorStyle?.normal?.stroke};
    border-image: initial;
    &:hover{
      background-color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.focussed.background};
      color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.focussed.text};
      border: solid 1px ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.focussed.stroke};
    }
}
li.active {
    background-color: ${({ theme: { compositeStyle } }) => compositeStyle?.selectorStyle?.selected?.background};
    color: ${({ theme: { compositeStyle } }) => compositeStyle?.selectorStyle?.selected?.text};
    border: 1px solid ${({ theme: { compositeStyle } }) => compositeStyle?.selectorStyle?.selected?.stroke};
  }
  .player-audio__head {
    p{
    color: ${({ theme: { body } }) => body && body?.text?.primary};
    }
  }
  .player-audio__content-list-wrapper {
    h3 {
        color: ${({ theme: { body } }) => body && body?.text?.primary};

    }
  }
`
/* --------------------------------------------- */
ChromeCastMiniController.propTypes = {}
/* --------------------------------------------- */
ChromeCastMiniController.defaultProps = {}
/* --------------------------------------------- */
export default ChromeCastMiniController
/* --------------------------------------------- */
