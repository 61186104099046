import { useCallback, useRef, useContext } from 'react'
import axios from 'axios'
import * as rax from 'retry-axios';
/* --------------------------------------------- */
import { getUserAuthInfo } from '../utils/localStorageService'
import utils from '../utils/utils'
/* --------------------------------------------- */
import useErrorHandler from '../containers/useErrorHandler'
/* --------------------------------------------- */
import VERSION from '../constants/apiVersionMap'
/* --------------------------------------------- */
import { GlobalContext } from '../context/global.context'
import useErrorUtils from '../utils/useErrorUtils';
/* --------------------------------------------- */
window.axios = axios
/* --------------------------------------------- */
rax.attach();
/* --------------------------------------------- */
axios.interceptors.response.use(response => response, (err) => {
    var config = err.config;
    
    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0;
    config.__retryCount += 1;
    return Promise.reject(err);
  }
)
const useGeneralApi = () => {
  const { handleError } = useErrorHandler()
  const { sessionId } = useContext(GlobalContext)
  const sessionIdRef = useRef(sessionId)
  const { showError } = useErrorUtils()
  /* --------------------------------------------- */
  const fetchApi = useCallback((config = {}, body = {}, headers = {}, other = {}) => {
    if ('Authorization' in headers && !headers.Authorization) {
      return Promise.reject(new Error(`Token Missing. URL: ${other.url}`))
    }
    headers = headers || {}
    other = other || {}
    
    if (sessionIdRef.current && (headers.Authorization || other.trustedLogin) && !other.isDoNotSetSessionId) {
      headers.session_id = sessionIdRef.current
    }
    /* --------------------------------------------- */
    if (Object.keys(body).length > 0) config.data = body
    if (Object.keys(headers).length > 0) config.headers = headers
    if (headers.responseType) {
      config.responseType = headers.responseType
      if (config.headers) delete config.headers.responseType
    }
    config.raxConfig = {
      retry: 3, // Retry 3 times on requests that return a response (500, etc) before giving up.  Defaults to 3.
      httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'POST'],
      statusCodesToRetry: [[100, 199], [401, 401], [429, 429], [500, 599]],
    }
    /* --------------------------------------------- */
    return axios(config).then(response => {
      // Enable below lines to reproduce 4010 error
      // if (config.url === 'https://api-videotron-qa.create.diagnal.com/accounts/trustedLogin') {
      //   return Promise.reject({
      //     response: {
      //       data: {
      //         errorCode: 4010
      //       }
      //     }
      //   })
      // }
      return response.data
    }).catch(error => {
      const networkErrorMessages = ["network error"];
      if (error?.message && error?.message.toLowerCase && networkErrorMessages.includes(error.message.toLowerCase())) {
        fetch("https://www.diagnal.com/", {
          mode: "no-cors",
        }).catch(() => {
          showError({ type: "AppNoNetwork" });
        })
      }
      
      error = error || {}
      if (error.response) {
        const { data = {}, status = '', statusText = '', headers = {}, config = {} } = error.response || {}

        const { message = {}, errorCode = null } = data || {}
        const apiError = new Error(message)
        apiError.errorMessage = statusText
        apiError.errorCode = errorCode
        apiError.errorStatus = status
        apiError.apiUrl = config.url
        apiError.custom = other
        apiError.requestBody = config.data?utils.parseJSON(config.data):""
        apiError.responseBody = data
        apiError.ikt = data?.ikt || ''
        apiError.responseId = headers['x-amzn-requestid']
        apiError.code = other.isContentfulApi ? 'A-6001' : 'A-4001'
        apiError.errorType = other.isContentfulApi ? 'ServerError' : 'API Error'
        apiError.retry_count = error.config.__retryCount || 1
        if (errorCode >= 7001 && errorCode <= 7005) {
          // No action required for error codes in this range
      } else {
          handleError(apiError, {}, error);
      }
        return Promise.reject(apiError)
      } else {
        return Promise.reject(error)
      }
    })
  }, [handleError, showError])
  /* --------------------------------------------- */
  const getData = useCallback((url, body = {}, headers = {}, config = {}, other = {}) => {
    return fetchApi({ method: 'GET', url, ...config }, body, headers, other)
  }, [fetchApi])
  /* --------------------------------------------- */
  const postData = useCallback((url, body = {}, headers = {}, config = {}, other = {}) => {
    return fetchApi({ method: 'POST', url, ...config }, body, headers, other)
  }, [fetchApi])
  /* --------------------------------------------- */
  const putData = useCallback((url, body = {}, headers = {}, config = {}, other = {}) => {
    return fetchApi({ method: 'PUT', url, ...config }, body, headers, other)
  }, [fetchApi])
  /* --------------------------------------------- */
  const deleteData = useCallback((url, body = {}, headers = {}, config = {}, other = {}) => {
    return fetchApi({ method: 'DELETE', url, ...config }, body, headers, other)
  }, [fetchApi])
  /* --------------------------------------------- */
  const getRailInfo = useCallback((baseUrl, dataUrl, params) => {
    dataUrl = dataUrl.split('://')
    const slug = dataUrl[1] || dataUrl[0]
    const url = `${baseUrl}${VERSION.getRailInfo}/content/filters/${slug}?${params}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  const getSeasonDetails = useCallback((baseUrl, seriesId, { language }) => {
    const url = `${baseUrl}${VERSION.getSeasonDetails}/content/items?type=season&filterBy=series&filter=${seriesId}&language=${language}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  const getSessionState = useCallback(() => {
    const authInfoLocal = getUserAuthInfo() || {}
    const now = Date.now() / 1000
    const nextRefreshTime = authInfoLocal.nextRefreshTime || null
    const identityProvider = authInfoLocal.identityProvider || ''
    if (!nextRefreshTime && (!identityProvider || identityProvider !== 'mpx')) return 'INVALID_SESSION'
    if (nextRefreshTime && (now > nextRefreshTime)) return 'SESSION_EXPIRED'
    return 'VALID_SESSION'
  }, [])
  /* --------------------------------------------- */
  const getChannelInfo = useCallback((baseUrl, channelID, query) => {
    const url = `${baseUrl}${VERSION.getChannelInfo}/content/channels?id=${channelID}&${query}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  const getListItems = useCallback((baseUrl, query) => {
    const url = `${baseUrl}${VERSION.getListItems}/content/items?${query}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  const getBestMatch = useCallback((baseUrl, { query, maxResultCount, searchMode, ratio, language, size, page, source }) => {
    let url = `${baseUrl}${VERSION.getBestMatch}/search?language=${language}`
    if (query) url += `&query=${query}`
    if (maxResultCount) url += `&maxResultCount=${maxResultCount}`
    if (searchMode) url += `&searchMode=${searchMode}`
    if (ratio) url += `&ratio=${ratio}`
    if (size) url += `&size=${size}`
    if (page) url += `&page=${page}`
    if (source) url += `&source=${source}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  const getSearchSuggestion = useCallback((baseUrl, { query, maxResultCount, ratio, language, profileId }) => {
    let url = `${baseUrl}${VERSION.getSearchSuggestion}/searchSuggestions?language=${language}`
    if (query) url += `&query=${query}`
    if (ratio) url += `&ratio=${ratio}`
    if (maxResultCount) url += `&maxResultCount=${maxResultCount}`
    if (profileId) url += `&profileId=${profileId}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  return {
    getData,
    putData,
    postData,
    deleteData,
    getRailInfo,
    getSeasonDetails,
    getChannelInfo,
    getListItems,
    getBestMatch,
    getSearchSuggestion,
    getSessionState
  }
}
/* --------------------------------------------- */
export default useGeneralApi
