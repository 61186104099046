import { PLAYER_FEATURE, SUPPORT_INFORMATION, CATALOG_FEATURE, REVENUE_FEATURE } from '../constants/actionTypes'
import utils from '../utils/utils'
import defaultPlayerConfig from '../defaults/playerConfig.default.json'
import { filter, hydrateLabel } from '../utils/contentfulHandler'
import { fetchSupportConfig } from '../actions/support.action'
import { fetchUserManagementConfig } from '../actions/userManagement.action'
import { fetchProfileManagementConfig } from '../actions/profileManagement.action'
import { fetchRevenueConfig } from '../actions/revenue.action'
import { fetchCatalogConfig } from '../actions/catalog.action'
import { fetchIntroConfig } from '../actions/intro.action'

/** FeaturesConfig store */
export const features = (features = [], themeData, options) => {
  return dispatch => {
    const { popupThemeData } = themeData
    features = filter(features, '', options)

    const playerFeature = features.map(item => {
      if (item.contentTypeId === 'featurePlayer') return item
      return null
    }).filter(feature => feature !== null)

    let relatedComponent = []
    if (utils.isNotNull(playerFeature, 0, 'relatedComponent', 0)) {
      relatedComponent = [playerFeature[0].relatedComponent]
    }

    let qualityMapping = []
    if (utils.isNotNull(playerFeature, 0, 'qualityMapping', 0)) {
      qualityMapping = hydrateLabel(playerFeature[0].qualityMapping)
    }

    let selectedFeatures = {}
    if (utils.isNotNull(playerFeature, 0)) {
      const feature = playerFeature[0]
      if (feature) {
        selectedFeatures = {
          qualityMappingMode: feature.qualityMappingMode,
          abrMode: feature.abrMode,
          debugModeEnabled: feature.debugModeEnabled,
          abrStartingProfile: feature.abrStartingProfile || [],
          maxResumePercent: feature.maxResumePercent || 95
        }
      }
    }

    let fullScreenConfigData = defaultPlayerConfig
    if (utils.isNotNull(playerFeature, 0, 'fullScreenConfig')) {
      fullScreenConfigData = filter(playerFeature[0].fullScreenConfig, '', options)
    }

    if (utils.isNotNull(fullScreenConfigData, 0)) {
      fullScreenConfigData = fullScreenConfigData[0]
    } else fullScreenConfigData = defaultPlayerConfig

    fullScreenConfigData.shakaConfig = {}

    if (utils.isNotNull(fullScreenConfigData, 'additionalConfiguration')) {
      const additionalConfiguration = JSON.parse(fullScreenConfigData.additionalConfiguration) || {}
      if (utils.isNotNull(additionalConfiguration, 'shaka')) {
        fullScreenConfigData.shakaConfig = additionalConfiguration.shaka
      }
    }

    let allowCasting = false
    if (utils.isNotNull(playerFeature, 0, 'allowCasting')) {
      allowCasting = playerFeature[0].allowCasting
    }

    let progressSaveInterval = 60
    if (utils.isNotNull(playerFeature, 0, 'progressSaveInterval')) {
      progressSaveInterval = playerFeature[0].progressSaveInterval
    }

    let allowMobileBrowserPlayback = false
    if (utils.isNotNull(playerFeature, 0, 'allowMobileBrowserPlayback')) {
      allowMobileBrowserPlayback = playerFeature[0].allowMobileBrowserPlayback
    }

    let playFromLastPosition = false
    if (utils.isNotNull(playerFeature, 0, 'playFromLastPosition')) {
      playFromLastPosition = playerFeature[0].playFromLastPosition
    }

    let castConfigData = defaultPlayerConfig
    if (utils.isNotNull(playerFeature, 0, 'castConfig')) {
      castConfigData = filter(playerFeature[0].castConfig, '', options)
    }

    if (utils.isNotNull(castConfigData, 0)) {
      castConfigData = castConfigData[0]
    } else castConfigData = defaultPlayerConfig

    let linearPlayerConfigData = defaultPlayerConfig
    if (utils.isNotNull(playerFeature, 0, 'linearPlayerConfig')) {
      linearPlayerConfigData = filter(playerFeature[0].linearPlayerConfig, '', options)
    }

    if (utils.isNotNull(linearPlayerConfigData, 0)) {
      linearPlayerConfigData = linearPlayerConfigData[0]
    } else linearPlayerConfigData = defaultPlayerConfig

    linearPlayerConfigData.shakaConfig = {}

    if (utils.isNotNull(linearPlayerConfigData, 'additionalConfiguration')) {
      const additionalConfiguration = JSON.parse(linearPlayerConfigData.additionalConfiguration) || {}
      if (utils.isNotNull(additionalConfiguration, 'shaka')) {
        linearPlayerConfigData.shakaConfig = additionalConfiguration.shaka
      }
    }

    let portraitConfigData = defaultPlayerConfig
    if (utils.isNotNull(playerFeature, 0, 'portraitConfig')) {
      portraitConfigData = filter(playerFeature[0].portraitConfig, '', options)
    }

    if (utils.isNotNull(portraitConfigData, 0)) {
      portraitConfigData = portraitConfigData[0]
    } else portraitConfigData = defaultPlayerConfig

    portraitConfigData.shakaConfig = {}

    if (utils.isNotNull(portraitConfigData, 'additionalConfiguration')) {
      const additionalConfiguration = JSON.parse(portraitConfigData.additionalConfiguration) || {}
      if (utils.isNotNull(additionalConfiguration, 'shaka')) {
        portraitConfigData.shakaConfig = additionalConfiguration.shaka
      }
    }

    let portraitPlayerEnabled = false
    if (utils.isNotNull(playerFeature, 0, 'portraitPlayerEnabled')) {
      portraitPlayerEnabled = playerFeature[0].portraitPlayerEnabled
    }

    dispatch({
      type: PLAYER_FEATURE,
      value: {
        fullScreenConfig: fullScreenConfigData,
        linearPlayerConfig: linearPlayerConfigData,
        portraitConfig: portraitConfigData,
        castConfig: castConfigData,
        allowCasting,
        progressSaveInterval,
        qualityMapping,
        playFromLastPosition,
        portraitPlayerEnabled,
        allowMobileBrowserPlayback,
        relatedComponent,
        ...selectedFeatures
      }
    })

    let supportInformation = features.map(item => {
      if (item.contentTypeId === 'featureSupport') return item
      return null
    }).filter(Boolean)
    supportInformation = filter(supportInformation, '', options) || []

    const supportPromise = dispatch(fetchSupportConfig(supportInformation, popupThemeData, options))

    if (utils.isNotNull(supportInformation, 0)) {
      const config = { ...supportInformation[0] }
      let faqData = []
      if (config.faqData) {
        faqData = filter(config.faqData, '', options)
      }
      config.faqData = faqData
      dispatch({
        type: SUPPORT_INFORMATION,
        value: config
      })
    }

    const userManagement = features.map(item => {
      if (item.contentTypeId === 'featureUserManagement') return item
      return null
    }).filter(Boolean)
    const userManagementPromise = dispatch(fetchUserManagementConfig(userManagement, options, themeData))

    const profileManagement = features.map(item => {
      if (item.contentTypeId === 'featureProfileManagement') return item
      return null
    }).filter(Boolean)
    const profileManagementPromise = dispatch(fetchProfileManagementConfig(profileManagement, options, themeData))

    const revenueFeature = features.map(item => {
      if (item.contentTypeId === 'featureRevenue') return item
      return null
    }).filter(Boolean)
    const revenueFeaturePromise = dispatch(fetchRevenueConfig(revenueFeature, options, themeData))

    const introFeature = features.map(item => {
      if (item.contentTypeId === 'featureIntro') return item
      return null
    }).filter(Boolean)
    const introFeaturePromise = dispatch(fetchIntroConfig(introFeature, options, themeData))

    if (utils.isNotNull(revenueFeature, 0)) {
      dispatch({
        type: REVENUE_FEATURE,
        value: revenueFeature[0]
      })
    }

    const _featureCatalog = features.map(item => {
      if (item.contentTypeId === 'featureCatalog') return item
      return null
    }).filter(feature => feature !== null)
    if (utils.isNotNull(_featureCatalog, 0)) {
      dispatch({
        type: CATALOG_FEATURE,
        value: _featureCatalog[0]
      })
    }

    const featureCatalog = features.map(item => {
      if (item.contentTypeId === 'featureCatalog') return item
      return null
    }).filter(feature => feature !== null)
    const catalogPromise = dispatch(fetchCatalogConfig(featureCatalog, themeData, options))

    return Promise.all([supportPromise, userManagementPromise, catalogPromise, profileManagementPromise, revenueFeaturePromise, introFeaturePromise])
  }
}
